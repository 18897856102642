import jquery from 'jquery';
require('bootstrap');
require('prismjs');
require('prismjs/plugins/line-numbers/prism-line-numbers');
require('prismjs/components/prism-bash');
require('prismjs/components/prism-c');
require('prismjs/components/prism-java');
require('prismjs/components/prism-javascript');
require('prismjs/components/prism-go');
require('prismjs/components/prism-rust');
require('prismjs/plugins/autoloader/prism-autoloader');

globalThis.$ = jquery;
globalThis.jQuery = jquery;
